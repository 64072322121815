<template>
  <PageLayout>
    <template #header>
      <k-title>{{ candidate.name }}</k-title>
    </template>
    <template #flow-actions>
      <v-btn
        class="mx-1"
        color="primary"
        depressed
        tile
        @click="openUpdate"
      >
        {{ $t('actions.updateResource', { resource: $tc('candidate.title') }) }}
      </v-btn>
      <CandidateForm
        v-model="updateDialog"
        :request="updateRequest"
        :title="$tc('candidate.title')"
        :values="updateFormValues"
        is-update-form
        @change="getCandidate"
      />
    </template>
    <template
      v-if="candidate"
      #default
    >
      <k-display-group language-prefix="candidate.fields">
        <v-row>
          <v-col class="personal-details">
            <k-dossier>
              <v-row>
                <v-col cols="8">
                  <k-text-display field="name">
                    {{ candidate.name }}
                  </k-text-display>
                  <k-text-display
                    v-if="candidate.genderId"
                    field="gender"
                  >
                    {{
                      $t(`candidate.genderOptions.${candidate.genderId}`)
                    }}
                  </k-text-display>
                  <KLinkDisplay
                    :href="candidate.contactDetail.phone"
                    field="contactDetail.phone"
                    link-type="phone"
                  />
                  <KLinkDisplay
                    :href="candidate.contactDetail.mobilePhone"
                    field="contactDetail.mobilePhone"
                    link-type="phone"
                  />
                  <KLinkDisplay
                    :href="candidate.contactDetail.fax"
                    field="contactDetail.fax"
                    link-type="fax"
                  />
                  <KLinkDisplay
                    :href="candidate.contactDetail.linkedInUrl"
                    field="contactDetail.linkedInUrl"
                    link-type="website"
                  />
                  <KLinkDisplay
                    :href="candidate.contactDetail.website"
                    field="contactDetail.website"
                    link-type="website"
                  />
                  <k-text-display field="nationality">
                    {{ candidate.nationality }}
                  </k-text-display>
                  <k-text-display field="address">
                    <AddressDisplay :address="candidate.address" />
                  </k-text-display>
                  <k-text-display field="dateOfBirth">
                    {{ formattedDateOfBirth }}
                  </k-text-display>
                </v-col>
                <v-col cols="4">
                  <VImg
                    v-if="candidate.profilePicture"
                    :src="`${candidate.profilePicture.url}&type=small`"
                    :lazy-src="`${candidate.profilePicture.url}&type=lazy`"
                    class="mb-3"
                    width="200"
                  />
                </v-col>
              </v-row>
            </k-dossier>
          </v-col>
          <v-col class="motivation">
            <k-text-display field="motivation">
              {{ candidate.motivation }}
            </k-text-display>
          </v-col>
        </v-row>
      </k-display-group>
    </template>
  </PageLayout>
</template>

<script>
import eventBus from '@/application/eventBus.ts';
import Candidate from '@/application/models/Candidate.js';
import KDisplayGroup from '@/components/crud/display/KDisplayGroup.vue';
import KLinkDisplay from '@/components/crud/display/KLinkDisplay.vue';
import KTextDisplay from '@/components/crud/display/KTextDisplay.vue';
import PageLayout from '@/components/layout/PageLayout.vue';
import AddressDisplay from '@/modules/address/components/AddressDisplay.vue';
import { show, update } from '@/modules/candidate/api/candidate.js';
import CandidateForm from '@/modules/candidate/components/CandidateForm.vue';
import dayjs from '@/plugins/dayjs';
import KTitle from '@/components/layout/KTitle.vue';
import KDossier from '@/components/layout/KDossier.vue';

export default {
  name: 'CandidatePersonalDetails',
  components: {
    KDossier,
    KTitle,
    AddressDisplay,
    KLinkDisplay,
    KDisplayGroup,
    CandidateForm,
    PageLayout,
    KTextDisplay,
  },
  props: {
    candidate: {
      type: Object,
    },
    getCandidate: {
      type: Function,
    },
  },
  data: () => ({
    updateFormValues: new Candidate(),
    updateDialog: false,
  }),
  computed: {
    formattedDateOfBirth() {
      if (!this.candidate.dateOfBirth) {
        return null;
      }
      return dayjs(this.candidate.dateOfBirth).format('D MMMM YYYY');
    },
  },
  created() {
    eventBus.$emit('setBreadcrumbs', [
      {
        exact: true,
        to: { name: 'candidate.index' },
        text: this.$tc('candidate.title', 2),
      },
      {
        exact: true,
        to: { name: 'candidate.show' },
        text: this.candidate.name,
      }],
    );
  },
  methods: {
    updateRequest() {
      return update(this.updateFormValues);
    },
    async openUpdate() {
      this.updateFormValues = new Candidate();
      const response = await show(this.candidate.id);
      this.updateFormValues.mapResponse(response.data.data);
      this.updateDialog = true;
    },
  },
};
</script>
